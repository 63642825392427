<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100" style="
    background: linear-gradient(#f3c5c9,#ce525c);">
    <Menubar msg="Remind" />
      <ul>
      <li >
        <b-card  :img-src="xurl!==''? xurl:catImg('actimg.png')" style="margin:30px;max-width: 640px;padding:15px;border-radius:20px;box-shadow: 0 0 15px #ca9498;" img-alt="Card image"  img-top> 
            <b-card-text>            
            <router-link  class="nav-link" :to="{name:'Makact'}"><h4>健康社區發展平台, 歡迎加入我們一起辦活動.</h4></router-link>
            </b-card-text>
        </b-card>

      </li>
      </ul>
    <div class="wrapper flex-grow-1"></div>      
    <Footbar msg="Remind" /> 
    </div>
  </div>
</template>
<script>
import Menubar from '@/components/Menubar.vue'
import Footbar from '@/components/Footbar.vue'
export default {
  name: 'Remind',
  components: {
    Menubar,
    Footbar
  }, 
  props: {
    msg: String
  },
    data(){
    return {
        xurl :'',      
        }; 
    },
    methods :{
        catImg(imgs) {
            return require(`../assets/${imgs}`);
        }, 
    }    
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

